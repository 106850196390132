import axios, { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { authStore } from "../store/authStore";
import { BASE_URL } from "../api/config";


export function useRequest(url) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  async function makeRequest(payload, method = "post"){
    const token = authStore.authDetails.token
    try {
      setLoading(true)
      const { data } = await axios({
        baseURL: BASE_URL, url,
        method, 
        data: method !== "get" ? payload : null,
        params: method === "get" ? payload : null,
        headers: { 
          "Content-Type": "application/json",
          "Authorization": token ? "Bearer " + token : ""
        }
      })
      
      // if([api.login, api.verifyOtp].includes(url) && data.data && data.status === "success"){
      //   await setToken(data.data)
      // }

      if(method === "post"){
        toast(data?.message, {type: data?.status === "failed" ? "error": "success"})
      }
      setData(data)
      return data
    } catch (error) {
      if(error instanceof AxiosError){
        const data = error.response?.data
        setError(data)
        if(method === "post"){
          toast(data?.message, {type: data?.status === "failed" ? "error": "success"})
        }
        return data
      }
    }
    finally { 
      setLoading(false)
    }
  }

  return { makeRequest, loading, data, error }
}
import React from "react";
import DashboardLayout from "../../../layout/DashboardLayout";
import PasswordDetails from "./components/PasswordDetails";
import PersonalDetails from "./components/PersonalDetails";

const Settings = () => {
  return (
    <DashboardLayout>
      <div className="w-full max-w-[700px] flex items-center flex-col mx-auto">
        {/* <img
          src={require("../../../assets/images/profile-pic.png")}
          alt=""
          className="w-[100px] h-[100px] rounded-full"
        /> */}
        {/* <div className="relative my-3 cursor-pointer">
          <p className="text-sm text-primary cursor-pointer">Edit Photo</p>
          <input
            type="file"
            className="w-full absolute opacity-0 top-0 cursor-pointer h-full"
          />
        </div> */}
        <h2 className="text-2xl font-semibold pb-4 w-full pt-6">
          Personal Details
        </h2>
        <PersonalDetails />
        <h2 className="text-2xl font-semibold pb-4 w-full pt-6">
          Change Password
        </h2>
        <PasswordDetails />
      </div>
    </DashboardLayout>
  );
};

export default Settings;

import axios from "axios";
import { useEffect, useRef } from "react";

export const apiCaller = async (config) => {
    try {
        const response = await axios(config);
        return {
            status: response.status,
            data: response.data?.data,
            message: response.data?.message,
            error: response.data.error,
        };
    } catch (err) {
        return {
            status: err.response.status,
            data: err.response.data?.data,
            message: err.response.data.message,
            error: err.response.data.error,
        };
    }
}

export const statusColor = (status) => {
    switch(status){
        case "completed":
            return "#00FF00"
        case "new":
            return "#90EE90"
        case "pending":
            return "#FFA500"
        case "bill available":
            return "#0000FF"
        case "demand note ready":
            return "#A020F0"
        case "cancel":
            return "#FF0000"
        default: 
            return "#A020F0"
    }
}

export function useObserver({ cb }){
    const ref = useRef(null)
    useEffect(() => {
    const observer = new IntersectionObserver(
        entries => {
            if (entries[0].isIntersecting) cb();
        },
        { threshold: 1 }
    );

    if (ref.current) observer.observe(ref.current)

    return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
    };
    }, [ref]);

    return ref
}

export const isEmpty = (mixedVar) => {
    let undef;
    let key;
    let i;
    let len;
    const emptyValues = [undef, null, false, 0, '', '0', 'null', 'undefined'];
  
    for (i = 0, len = emptyValues.length; i < len; i++) {
      if (mixedVar === emptyValues[i] || typeof mixedVar == 'undefined') {
        return true;
      }
    }
  
    if (typeof mixedVar === 'object' && !(mixedVar instanceof Date)) {
        for (key in mixedVar) {
          if (mixedVar.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
    }
    return false;
};
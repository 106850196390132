import React, { useState } from "react";
import DashboardLayout from "../../../layout/DashboardLayout";
import TableHeader from "./components/TableHeader";
import TableItem from "./components/TableItem";
import { ContainedButton } from "../../../components/Button";
import { useEffect } from "react";
import { getUsers } from "../../../api/user";
import { LinearProgress, Paper, Table, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material";
import { toast } from "react-toastify";
import { AddUser } from "./components/AddUser";


const Users = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)

  console.log(data)

  useEffect(() => {
      setLoading(true)
      getUsers(page)
        .then(result => {
          console.log(result)
          if(result?.error) toast.error(result?.message)
          setData(result.data)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
  }, [page])
  
  return (
    <DashboardLayout>
      {open && <AddUser open={open} close={() => setOpen(false)}/>}
      <div className="flex justify-between item-center mt-4 flex-wrap">
        <h2 className="text-2xl font-semibold mb-3 mr-3">Manage Users</h2>
        <ContainedButton onClick={() => setOpen(true)} text="Add User" className="mb-3" />

      </div>
      <TableContainer component={Paper}>
        {loading && <LinearProgress /> }
        <Table>
          <TableHeader />
          {
            data?.docs.map((item, index) => (
              <TableItem index={index} item={item} key={index.toString()} />
            ))
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[ data?.limit ]}
                count={data?.totalPages}
                rowsPerPage={data?.limit}
                page={page - 1}
                onPageChange={(e, page) => setPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default Users;

import { apiCaller } from "../helpers/utils";
import { authStore } from "../store/authStore";
import { BASE_URL } from "./config";

export const createDept = async (data) => {
    return await apiCaller({
        method: "POST",
        url: `${BASE_URL}/departments`,
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const getDepts = async () => {
    return await apiCaller({
        method: "GET",
        url: `${BASE_URL}/departments`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const getAllDepts = async () => {
  return await apiCaller({
      method: "GET",
      url: `${BASE_URL}/departments/all`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}

export const getDept = async (id) => {
    return await apiCaller({
        method: "GET",
        url: `${BASE_URL}/departments/${id}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const getDeptUsers = async ({id, page = 1}) => {
  return await apiCaller({
      method: "GET",
      url: `${BASE_URL}/departments/users/${id}?page=${page}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}

export const editDept = async (data) => {
    return await apiCaller({
        method: "PUT",
        url: `${BASE_URL}/departments`,
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const deleteDept = async (id) => {
    return await apiCaller({
        method: "DELETE",
        url: `${BASE_URL}/departments/${id}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const addUserToDept = async (data) => {
    return await apiCaller({
        method: "PUT",
        url: `${BASE_URL}/departments/user`,
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const removeUserFromDept = async (data) => {
  return await apiCaller({
      method: "DELETE",
      url: `${BASE_URL}/departments/users`,
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}

import { computed, observable, action, makeObservable } from "mobx";

class DepartmentStore {
  departments = []

  constructor() {
    makeObservable(this, {
        departments: observable,
        loadDepartments: action.bound,
        addDepartment: action.bound,
        deleteDepartment: action.bound,
        getDepartments: computed,
    });
  }

  loadDepartments(departments) {
    this.departments = departments;
  }

  addDepartment(department) {
    this.departments.push(department)
  }

  deleteDepartment(id){
    const index = this.departments.findIndex(department => department?.id === id)

    this.departments.splice(index, 1)
  }

  get getDepartments() {
    return {
      departments: this.departments
    };
  }
}

export const departmentStore = new DepartmentStore();

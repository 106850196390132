import { toast } from "react-toastify";
import { createUser } from "../../../../api/user";
import React, { useEffect, useState } from "react";
import { Button, Dialog, Select, FormControl, InputLabel, MenuItem, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { userStore } from "../../../../store/userStore";
import { useRequest } from "../../../../hooks/use-request";
import endpoint from "../../../../api/endpoint";

export const AddUser = ({open, close}) => {
  const { makeRequest, data } = useRequest(endpoint.all_dept)
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState({
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      department: "",
      role: "staff"
  })

  useEffect(() => {
    makeRequest(null, "get")
  }, [])

  function submit(e){
      setLoading(true)
      e.preventDefault()
  
      createUser(JSON.stringify(inputs))
        .then(result => {
          if(!result?.error){
            toast.success(result.message)
            userStore.addUser(result?.data)
          }
          else{
            toast.error(result.message)
          }
          setLoading(false)
        })
        .catch(err => {
          if(err?.message){
            toast.error(err.message)
          }
          else{
            console.log(err)
          }
          setLoading(false)
        })
  }
  return(
    <Dialog
      open={open}
      onClose={close}
      className="w-full"
      >
      <DialogTitle>Add User</DialogTitle>
      <form onSubmit={submit} className='w-[500px]'>
        <DialogContent>

          <div className='flex gap-2 my-2'>
              <TextField
                fullWidth
                value={inputs.first_name}
                onChange={(e) => setInputs(state => ({...state, first_name: e.target.value}))}
                label="First name"
              />
              <TextField
                fullWidth
                value={inputs.last_name}
                onChange={(e) => setInputs(state => ({...state, last_name: e.target.value}))}
                label="Last name"
              />
          </div>

          <div className='my-2'>
            <TextField
                fullWidth
                value={inputs.email}
                onChange={(e) => setInputs(state => ({...state, email: e.target.value}))}
                label="Email"
            />
          </div>
          <div className='my-2'>
            <TextField
              fullWidth
              value={inputs.phone_number}
              onChange={(e) => setInputs(state => ({...state, phone_number: e.target.value}))}
              label="Phone number"
            />
          </div>

          <div className='my-2'>
            <FormControl fullWidth>
              <InputLabel id="select-staff">Select Department</InputLabel>
              <Select
                value={inputs.department}
                label="Select Department"
                onChange={(e) => setInputs(state => ({...state, department: e.target.value}))}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                { data?.data?.map(d => (<MenuItem value={d?._id}>{ d?.name }</MenuItem>)) }
              </Select>
            </FormControl>
          </div>
      
          <DialogActions>
            <Button onClick={close}>Close</Button>
            <LoadingButton
              variant='contained' 
              type='submit'
              loading={loading}
              autoFocus>
              Submit 
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  )
}
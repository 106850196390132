import React, { useState } from "react";
import moment from 'moment';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddDepartment, { AddUserToDepartment, ViewDepartment } from "./AddDepartment";
import PeopleIcon from '@mui/icons-material/People';

const TableItem = ({ item }) => {
  const [open, setOpen] = useState(false)
  const [view, setView] = useState(false)
  const [type, setType] = useState("edit")
  console.log(item)
  return (
    <>
      <AddDepartment open={open} close={() => setOpen(false)} type={type} data={item} />
      {view && <ViewDepartment open={view} close={() => setView(false)} data={item}/>}
      <div className="grid grid-cols-3 gap-4 py-3 border-t border-slate-500 border-solid min-w-[600px]">
        <div className="col-span-1 flex justify-center items-center capitalize">
          { item.name }
        </div>
        <div className="col-span-1 flex justify-center items-center capitalize">
          { moment(item?.createdAt).format('lll')}
        </div>
        <div className="col-span-1 flex justify-center items-center capitalize relative">
          <IconButton
             onClick={() => setView(true)}
          >
            <PeopleIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setType("edit")
              setOpen(true)
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setType("delete")
              setOpen(true)
            }}
          >
            <DeleteIcon sx={{ color: "crimson" }}/>
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default TableItem;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getDepts } from "../../../api/department";
import DashboardLayout from "../../../layout/DashboardLayout";
import TableHeader from "./components/TableHeader";
import TableItem from "./components/TableItem";
import { departmentStore } from "../../../store/departmentStore";
import { toast } from "react-toastify";
import { ContainedButton } from "../../../components/Button";
import AddDepartment from "./components/AddDepartment";
import { CircularProgress } from "@mui/material";

const Department = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDepts()
      .then((data) => {
        if (data?.error) toast.error(data?.message);
        else departmentStore.loadDepartments(data.data.docs);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.error) toast.error(err?.message);
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <AddDepartment open={open} close={() => setOpen(false)} type="create" />
      <div className="flex justify-between item-center mt-4 flex-wrap">
        <h2 className="text-2xl font-semibold mb-3 mr-3">Manage Laboratory</h2>
        <ContainedButton
          text="Add Laboratory"
          className="mb-3"
          onClick={() => setOpen(true)}
        />
      </div>

      <div className="py-3 px-3 rounded-md shadow-md mt-8 bg-white overflow-x-auto mb-5">
        <TableHeader />
        {loading ? (
          <div className="w-full flex h-full items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          departmentStore.getDepartments.departments.map((item, index) => (
            <TableItem index={index} item={item} key={index.toString()} />
          ))
        )}
      </div>
    </DashboardLayout>
  );
};

export default Department;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getRequests } from "../../../api/request";
import DashboardLayout from "../../../layout/DashboardLayout";
import TableHeader from "./components/TableHeader";
import TableItem from "./components/TableItem";
import { toast } from "react-toastify";
import { LinearProgress, Paper, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material";

const Request = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState(null)

  useEffect(() => {
      setLoading(true)
      getRequests(page)
        .then(data => {
          if(data?.error) toast.error(data?.message)
          
          setData(data.data)
          setLoading(false)
        })
        .catch(err => {
          if(err?.error) toast.error(err?.message)
          setLoading(false)
        })
  }, [page])
  return (
    <DashboardLayout>
      <div className="flex justify-between item-center mt-4 flex-wrap">
        <h2 className="text-2xl font-semibold mb-3 mr-3">Manage Requests</h2>
      </div>
      <TableContainer component={Paper}>
        {loading && <LinearProgress /> }
        <Table>
          <TableHeader />
            <TableBody>
              {
                data?.docs.map((item, index) => (
                  <TableItem index={index} item={item} key={index.toString()} />
                ))
              }
            </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[ data?.limit ]}
                count={data?.totalPages}
                rowsPerPage={data?.limit}
                page={page - 1}
                onPageChange={(e, page) => setPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
        </TableContainer>
    </DashboardLayout>
  );
};

export default Request;

import React, { useState } from "react";
import { ContainedButton } from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";

const PersonalDetails = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const validate = () => {};
  return (
    <div className="w-full grid grid-cols-2 gap-4 my-3">
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">First Name</p>
        <TextInput
          value={firstName}
          setValue={(e) => setFirstName(e.target.value)}
          placeholder="Enter first name"
          className="bg-transparent text-md rounded-md"
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">Last Name</p>
        <TextInput
          value={lastName}
          setValue={(e) => setLastName(e.target.value)}
          placeholder="Enter last name"
          className="bg-transparent text-md rounded-md"
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">Phone Number</p>
        <TextInput
          value={phone}
          type="tel"
          setValue={(e) => setPhone(e.target.value)}
          placeholder="Enter phone number"
          className="bg-transparent text-md rounded-md"
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">Address</p>
        <TextInput
          value={address}
          type="text"
          setValue={(e) => setAddress(e.target.value)}
          placeholder="Enter Address"
          className="bg-transparent text-md rounded-md"
        />
      </div>
      <div className="col-span-2">
        <ContainedButton
          text={"Update Details"}
          className="w-full mt-4"
          onClick={validate}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;

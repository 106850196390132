import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import ClickAwayListener from "react-click-away-listener";
import { BsThreeDots } from "react-icons/bs";
import ActionPanel from "./ActionPanel";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ViewUser } from "./ViewUser";

const TableItem = ({ item }) => {
  const [display, setDisplay] = useState(false);
  const [view, setView] = useState(false)
  return (
    <>
      {
        view && <ViewUser open={view} close={() => setView(false)} data={item}/>
      }
      <TableRow hover>
        <TableCell className="capitalize">
          {item?.first_name + " " + item?.last_name}
        </TableCell>
        <TableCell>
          {item?.email}
        </TableCell>
        <TableCell>
          {item?.phone_number}
        </TableCell>
        <TableCell className="capitalize">
          {item?.role}
        </TableCell>
        {/* <div className="col-span-1 flex justify-center items-center relative">
          <BsThreeDots
            className="text-2xl text-primary cursor-pointer"
            onClick={() => setDisplay(!display)}
          />
          {display && (
            <ClickAwayListener onClickAway={() => setDisplay(false)}>
              <ActionPanel email={item.email} />
            </ClickAwayListener>
          )}
        </div> */}
        <TableCell>
            <IconButton
              onClick={() => setView(true)}
            >
              <VisibilityIcon />
            </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableItem;

import axios from "axios";
import { apiCaller } from "../helpers/utils";
import { authStore } from "../store/authStore";
import { BASE_URL } from "./config";

export const getUserProfile = async () => {
    return await apiCaller({
        method: "GET",
        url: `${BASE_URL}/user/profile`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const editUser = async (data) => {
    return await apiCaller({
        method: "PUT",
        url: `${BASE_URL}/user`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const changePassword = async () => {
    return await apiCaller({
        method: "POST",
        url: `${BASE_URL}/user/change-password`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const createUser = async (data) => {
    return await apiCaller({
        method: "POST",
        url: `${BASE_URL}/user`,
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const getUsers = async (page) => {
    return await apiCaller({
        method: "GET",
        url: `${BASE_URL}/user?page=${page}&limit=20`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const getAllStaff = async () => {
  return await apiCaller({
      method: "GET",
      url: `${BASE_URL}/user/staff`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}
import { apiCaller } from "../helpers/utils";
import { authStore } from "../store/authStore";
import { BASE_URL } from "./config";

export const getRequests = async (page = 1) => {
  return await apiCaller({
    method: "GET",
    url: `${BASE_URL}/request?page=${page}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authStore.authDetails.token
    },
  })
}

export const getRequest = async (id) => {
  return await apiCaller({
      method: "GET",
      url: `${BASE_URL}/request/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}

export const assignRequest = async (data) => {
    return await apiCaller({
        method: "PUT",
        url: `${BASE_URL}/request/assign`,
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authStore.authDetails.token
        },
    })
}

export const uploadDocs = async (data, type) => {
  return await apiCaller({
    method: "POST",
    url: `${BASE_URL}/request/${type}`,
    data,
    headers: {
      "Authorization": "Bearer " + authStore.authDetails.token
    },
  })
}

export const deleteRequestFile = async (id, resoureses) => {
  return await apiCaller({
      method: "DELETE",
      url: `${BASE_URL}/request/img/${resoureses}/${id}`,
      headers: {
        "Authorization": "Bearer " + authStore.authDetails.token
      },
  })
}
import { observer } from "mobx-react";
import React from "react";
import { Navigate} from "react-router-dom";
import { authStore } from "../store/authStore";

export const AdminRoute = observer(({ children, redirectTo }) => {

  if (!authStore.authDetails.isLoggedIn) {
    return <Navigate  to={`/login`} replace />;
  }

  return (
      <>
          {children}
      </>
  ) 
});

export const PrivateRoute = observer(({ children, redirectTo }) => {
  console.log(authStore.authDetails.isLoggedIn)

  if (!authStore.authDetails.isLoggedIn) {
    return <Navigate  to={`${redirectTo}`} replace />;
  }

  return (
      <>
          {children}
      </>
  ) 

  // return authStore.authDetails.isLoggedIn ? (
  //   children
  // ) : (
  //   <>{navigate(`${redirectTo}`)}</>
  // );
});


import { computed, observable, action, makeObservable } from "mobx";

class UserStore {
  // user = null;
  users = []
  data = {
    docs: [],
    total: 0,
    page: 1
  }

  constructor() {
    makeObservable(this, {
      data: observable,
      loadUsers: action.bound,
      getUsers: computed,
    });
  }

  loadUsers(data) {
    this.data = data;
  }

  addUser(data) {
    this.data.docs.push(data)
    this.data.total ++
  }

  get getUsers() {
    return {
      data: this.data
    };
  }
}

export const userStore = new UserStore();

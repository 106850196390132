import React, { useState } from "react";
import { ContainedButton } from "../../../../components/Button";
import PasswordTextInput from "../../../../components/PasswordTextInput";

const PasswordDetails = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const validate = () => {};
  return (
    <div className="w-full grid grid-cols-2 gap-4 my-3">
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">Old Password</p>
        <PasswordTextInput
          value={oldPassword}
          setValue={(e) => setOldPassword(e.target.value)}
          placeholder="Enter old password"
          className="bg-transparent text-md rounded-md"
          contClassName={"rounded-md"}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <p className="text-md text-slate-800 mb-2 font-roboto">New Password</p>
        <PasswordTextInput
          value={newPassword}
          setValue={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password."
          className="bg-transparent text-md rounded-md"
        />
      </div>
      <div className="col-span-2">
        <ContainedButton
          text={"Save Changes"}
          className="w-full mt-4"
          onClick={validate}
        />
      </div>
    </div>
  );
};

export default PasswordDetails;

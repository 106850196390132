import {Dialog, DialogActions, DialogContent, ListItemText, Button, DialogTitle} from '@mui/material';

export const ViewUser = ({open, close, data}) => {
    return(
        <Dialog
            open={open}
            onClose={close}
            className="w-full"
        >
            <div className='w-[500px]'>
                <DialogTitle>{data ? data?.first_name + " " + data?.last_name : "Name"}</DialogTitle>
                <DialogContent>
                    <div className='flex justify-between'>
                        <ListItemText primary="First name" secondary={data?.first_name} />
                        <ListItemText primary="Last name" secondary={data?.last_name} />
                    </div>
                    <div className='flex justify-between'>
                        <ListItemText primary="Email" secondary={data?.email} />
                        <ListItemText primary="Phone number" secondary={data?.phone_number} />
                    </div>
                    <div className='flex justify-between'>
                        <ListItemText primary="Address" secondary={data?.address || "___"} />
                        <ListItemText primary="LGA" secondary={data?.lga || "___"} />
                    </div>
                    <div className='flex justify-between'>
                        <ListItemText primary="State" secondary={data?.state || "___"} />
                        <ListItemText primary="Role" secondary={data?.role} />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Close</Button>
                    {/* <LoadingButton 
                    variant='contained' 
                    type='submit'
                    loading={loading}
                    autoFocus>
                    Submit 
                    </LoadingButton> */}
                </DialogActions>
            </div>
        </Dialog>
    )
}
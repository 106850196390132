import { Rating, Dialog, DialogActions, DialogContent, ListItemText, Button, DialogTitle} from '@mui/material';

export const SeeReview = ({ open, close, data }) => {
  return(
    <Dialog open={open} onClose={close}className="w-full">
      <div className='w-[500px]'>
        <DialogTitle>Customer review</DialogTitle>
        <DialogContent>
          <div className="flex w-full justify-center my-5">
            <Rating
              name="rate"
              readOnly
              value={data?.rate}
              size="large"
            />
          </div>
          <ListItemText primary="Comment" secondary={data?.comment} />
          <ListItemText primary="Improvement" secondary={data?.improvement} />

        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
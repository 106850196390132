import { LoadingButton } from "@mui/lab"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getAllStaff } from "../../../../api/user"
import { assignRequest } from "../../../../api/request"

export const AssignRequest = ({close, open, data}) => {
    const [staffs, setStaffs] = useState([])
    const [ids, setIds] = useState([])
    const [loading, setLoading] = useState(false)

    async function fetchStaff(){
        const { error, message, data } = await getAllStaff()
        console.log(data)
        if(error) toast.error(message);
        if(data){
            setStaffs(data.docs)
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    async function submit(e) {
        setLoading(true)
        e.preventDefault()

        try {
            const res = await assignRequest(JSON.stringify({id: data?._id, staffs: ids}))
            if(res?.error) toast.error(res?.message)
            else{
                toast.success(res?.message)
                close()
            }
        } catch (err) {
            if(err?.error) toast.error(err?.message)
        }
        setLoading(false)
    }
    return (
        <Dialog
            open={open}
            onClose={close}
        >
            <DialogTitle>Assign Request</DialogTitle>
            <form onSubmit={submit}>
                <DialogContent>
                    <div className='my-1 w-[500px]'>
                        <FormControl fullWidth size="small">
                        <InputLabel id="select-dept">Select staff</InputLabel>
                        <Select
                            labelId="select-dept"
                            id="select-dept"
                            multiple
                            value={ids}
                            label="Select user"
                            renderValue={selected => <RenderValue {...{selected, staffs }}  />}
                            onChange={(e) => setIds(e.target.value)}
                        >
                            <MenuItem disabled><em>None</em></MenuItem>
                            {
                                staffs?.map(staff => (
                                    <MenuItem value={staff?._id}>
                                        <Checkbox checked={ids.includes(staff?._id)} />
                                        <ListItemText>{staff?.first_name + " " + staff?.last_name}</ListItemText>
                                    </MenuItem>
                                ))
                            }
                            {/* <MenuItem ref={ref}>Loading...</MenuItem> */}
                        </Select>
                        </FormControl>
                    </div>
                    <DialogActions>
                        <Button onClick={close}>Close</Button>
                        <LoadingButton 
                            type="submit"
                            variant="contained"
                            loading={loading}
                        >Assign</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    )
}

function RenderValue({ selected, staffs }){
    return selected.map(item => {
        const staff = staffs.find(staff => staff?._id === item )
        return <span>{ staff?.first_name + " " + staff?.last_name },  </span>
    })
}
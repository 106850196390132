import {Slide, AppBar, Toolbar, IconButton, Typography, Dialog, DialogContent, CircularProgress, ListItemText, Grid, DialogActions, Button, DialogTitle} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteRequestFile, getRequest } from '../../../../api/request';
import { toast } from 'react-toastify';
import moment from 'moment';
import { UploadFile } from './UploadFile';
import ImagePreview from '../../../../components/ImagePreview';
import { AssignRequest } from './AssignRequest';
import { authStore } from "../../../../store/authStore";
import Instrument from './Instruments';
import { SeeReview } from './SeeReview';
import { isEmpty } from '../../../../helpers/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export const ViewRequest = ({open, close, data=null}) => {
    const [loading, setLoading] = useState(false)
    const [assign, setAssign] = useState(false)
    const [upload, setUpload] = useState({ open: false, type: "certificate"})
    const [request, setRequest] = useState(null)
    const [review, setReview] = useState(false)
    console.log(request)
    useEffect(() => {
      setLoading(true)
      getRequest(data?._id)
        .then(data => {
          if(data?.error) toast.error(data?.message)
          else{
              setRequest(data?.data)
          }
          setLoading(false)
        })
        .catch(err => {
          if(err?.error) toast.error(err?.message)
          setLoading(false)
        })
    }, [])

    const attributes = [
        {
            title: "Organization Name",
            value: request?.org_name
        },
        {
            title: "User name",
            value: request?.user?.first_name + " " + request?.user?.last_name
        },
        {
            title: "Email",
            value: request?.email
        },
        {
            title: "Phone Number",
            value: request?.phone_number
        },
        {
            title: "Status",
            value: request?.status
        },
        {
            title: "Address",
            value: request?.address
        },
        {
            title: "Extra Information",
            value: request?.extra_info
        },
        {
            title: "Assigned By",
            value: request?.assign_by ? request?.assign_by?.first_name + " " + request?.assign_by?.last_name : "Not yet assigned"
        },
        {
            title: "Assigned On",
            value: request?.assign_on ? moment(request?.assign_on).format("lll") : "Not Yet Assigned"
        },
        {
            title: "Assigned Staffs",
            value: request?.staffs.filter(s => typeof s === "object").map(s => s.first_name + " " + s.last_name).join(", ")
        },
        {
            title: "Department",
            value: request?.department?.name || null
        }
    ]

    return(
      <>
        {upload.open && <UploadFile open={upload.open} close={() => setUpload(state => ({...state, open: false}))} type={upload.type} data={request} setRequest={setRequest}/>}
        {assign && <AssignRequest open={assign} close={() => setAssign(false)} data={request}/>}
        {review && <SeeReview open={review} close={() => setReview(false)} data={request?.review}/>}
        <Dialog
            fullScreen
            open={open}
            onClose={close}
            TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={close}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Request Details
              </Typography>
          </Toolbar>
          </AppBar>
          {
            loading ? 
            <div className='w-full flex justify-center mx-auto'>
              <CircularProgress />
            </div> :
            <DialogContent>
              <Grid container spacing={2}>
                  {
                    attributes.map((attribute, i) => (
                      <Grid item xs={8} sm={4} md={4} key={i}>
                        {
                          attribute?.value &&
                          <ListItemText primary={attribute.title} secondary={attribute.value} />
                        }
                      </Grid>
                    ))
                  }
              </Grid>
              <ListItemText primary={"Instruments"} />
              <Instrument instruments={request?.instrument} />
            
              <Documents 
                title="Callibration Bill" 
                setUpload={setUpload} 
                setRequest={setRequest}
                docs={request?.callibration} 
                type="callibration" 
              />
              <Documents 
                title="Demand Note" 
                setUpload={setUpload} 
                setRequest={setRequest}
                docs={request?.demand} 
                type="demand" 
              />
              <Documents 
                title="Evidence of payment" 
                setUpload={setUpload} 
                setRequest={setRequest}
                enable_upload={false}
                docs={request?.payment} 
                type="payment" 
              />
              <Documents 
                title="Job Scheduling" 
                setUpload={setUpload} 
                setRequest={setRequest}
                docs={request?.schedule} 
                type="schedule" 
              />
              <Documents 
                title="Certificate" 
                setUpload={setUpload} 
                setRequest={setRequest}
                docs={request?.certificate} 
                type="certificate" 
              />
            </DialogContent>
          }
          <DialogActions>
              <Button disabled={isEmpty(request?.review)} onClick={() => setReview(true)}>See Review</Button>
              {
                  (authStore?.authDetails?.role === "admin") &&
                  <Button 
                      onClick={() => setAssign(true)}
                      variant="contained"
                  >Assign Request</Button>
              }
          </DialogActions>
        </Dialog>
      </>
    )
}

function Documents({title, setUpload, setRequest, docs, type, enable_upload = true}){
  function handleUpload(){
    setUpload(state => ({ ...state,open: true, type }))
  }

  async function deleteFile(id){
    try {
      const data = await deleteRequestFile(id, type)
      if(data?.error) toast.error(data?.message)
      else{
        toast.success(data?.message)
        setRequest(state => ({
          ...state, 
          [type]: state[type].filter(item => item._id !== id) 
        }))
      }
    } catch (error) {
      if(error?.error) toast.error(error?.message)
    }
  }

  return(
    <div className='md:w-8/12 my-3'>
      <div className='flex justify-between items-center'>
        <Typography variant="h6">{ title }</Typography>
        { enable_upload && <Button onClick={handleUpload}> Upload { title }</Button> }
      </div>
      <div className="w-full grid grid-cols-4 gap-5">
        {
          docs?.map((file, i) => (
            <ImagePreview item={file}  key={i} onDelete={() => deleteFile(file._id)}/>
          ))
        }
      </div>
    </div>
  )
}
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./pages/auth/ChangePassword";
import Forgot from "./pages/auth/Forgot";
import Login from "./pages/auth/Login";
// import PublicRoute from "./components/PublicRoute";
import { AdminRoute, PrivateRoute } from "./components/PrivateRoute";
import Verify from "./pages/auth/Verify";
// import AddUser from "./pages/dashboard/AddUser";
// import CallibrationBilling from "./pages/dashboard/Billing";
// import Calibration from "./pages/dashboard/Calibration";
// import Certification from "./pages/dashboard/Certification";
// import DemandNote from "./pages/dashboard/DemandNote";
import Overview from "./pages/dashboard/Overview";
import Request from "./pages/dashboard/Requests";
// import Satisfaction from "./pages/dashboard/Satisfaction";
import Settings from "./pages/dashboard/Settings";
import Transactions from "./pages/dashboard/Transactions";
import Users from "./pages/dashboard/Users";
import Department from "./pages/dashboard/Department";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/dashboard" element={
          <PrivateRoute>
            <Overview />
          </PrivateRoute>
        } /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/requests" element={
          <PrivateRoute redirectTo="/login">
            <Request />
          </PrivateRoute>
        } />
        <Route path="/departments" element={
          <AdminRoute redirectTo="/login">
            <Department />
          </AdminRoute>
        } />
        {/* <Route
          path="/requests/view-calibration/:id"
          element={<Calibration />}
        />
        <Route
          path="/requests/callibration-billing/:id"
          element={<CallibrationBilling />}
        /> */}
        {/* <Route path="/requests/certificate/:id" element={<Certification />} /> */}
        {/* <Route path="/requests/demand-note/:id" element={<DemandNote />} /> */}
        {/* <Route path="/requests/satisfaction/:id" element={<Satisfaction />} /> */}
        <Route path="/transactions" element={<Transactions />} />
        {/* <Route path="/overview" element={
          <PrivateRoute redirectTo="/">
            <Overview />
          </PrivateRoute>
        } /> */}
        <Route path="/settings" element={
          <PrivateRoute redirectTo="/login">  
            <Settings />
          </PrivateRoute>
        } />
        <Route path="/users" element={
          <AdminRoute redirectTo="/login">
            <Users />
          </AdminRoute>
        } />
        {/* <Route path="/users/add-user" element={<AddUser />} /> */}
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;

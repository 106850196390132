import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, TableFooter, TablePagination, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {  
  Table, Paper, TableBody, 
  TableCell, TableRow , TableHead, 
  TableContainer, Select 
}from '@mui/material';
import { addUserToDept, createDept, deleteDept, editDept, getDeptUsers, removeUserFromDept } from '../../../../api/department';
import { useEffect } from 'react';
import { getAllStaff } from '../../../../api/user';
import DeleteIcon from '@mui/icons-material/Delete';
import { departmentStore } from "../../../../store/departmentStore"

const AddDepartment = ({ open, close, type, data = null }) => {
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)

    function submit(e) {
      setLoading(true)
      e.preventDefault()

      if(type ==="edit"){
        editDept(JSON.stringify({name, id: data?._id}))
          .then(data => {
            console.log(data)
            if(data?.error) return toast.error(data?.message)
            else{
              console.log(data?.data)
              toast.success(data?.message)
            }
            setLoading(false)
            close()
          })
          .catch(err => {
            if(err?.error) return toast.error(err?.message)
            setLoading(false)
          })
      }
      else{
        createDept(JSON.stringify({name}))
          .then(data => {
            console.log(data)
            if(data?.error) return toast.error(data?.message)
            else{
              console.log(data?.data)
              departmentStore.addDepartment(data?.data)
              toast.success(data?.message)
            }
            setLoading(false)
            close()
            setLoading(false)
          })
          .catch(err => {
            if(err?.error) return toast.error(err?.message)
            setLoading(false)
          })
      }
    }

    function deleteDepartment() {
      setLoading(true)
      deleteDept(data?.id)
          .then(res => {
            if(res?.error)toast.error(res?.message)
            else{
              departmentStore.deleteDepartment(data?._id)
              toast.success(res?.message)
            }
            setLoading(false)
            close()
          })
          .catch(err => {
            if(err?.error) return toast.error(err?.message)
            setLoading(false)
          })
    }
    return (
        <Dialog
        open={open}
        onClose={close}
        className="w-full"
      >
        <div className='w-[500px]'>
          <DialogTitle>
            {
              type ==="edit" ? 
              "Edit" : 
              type === "delete" ?
              "Delete" :
              "Add"
            } Laboratory
          </DialogTitle>
          {
            type === "delete" ?
            <>
              <DialogContent>
                Are you Sure you want to delete {data?.name}
              </DialogContent> 
              <DialogActions>
                <Button onClick={close}>Close</Button>
                <LoadingButton 
                  variant='contained' 
                  onClick={deleteDepartment}
                  loading={loading}
                  autoFocus>
                  Delete
                </LoadingButton>
              </DialogActions>
            </>
            :
          
            <form onSubmit={submit}>
              <DialogContent>
                <div className='my-1'>
                  <TextField
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label={data?.name || "Department name"}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={close}>Close</Button>
                <LoadingButton 
                  variant='contained' 
                  type='submit'
                  loading={loading}
                  autoFocus>
                  Submit 
                </LoadingButton>
              </DialogActions>
            </form>
          }
        </div>
      </Dialog>

    )
}

export const ViewDepartment = ({open, close, data = null}) => {
  const[add, setAdd] = useState(false)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [users, setUsers] = useState(null)

  useEffect(() => {
    setLoading(true)
    getDeptUsers({id: data?._id, page: page + 1})
      .then(data => {
        console.log(data)
        if(data?.error) return toast.error(data?.message)
        setUsers(data?.data)
        setLoading(false)
      })
      .catch(err => {
        if(err?.error)toast.error(err?.message)
        setLoading(false)
      })
  }, [])

  async function removeUser(user_uuid){
    setRemoving(true)
    const res = await removeUserFromDept(JSON.stringify({user_uuid, department_id: data?.id}))
    if(res?.error){
      toast.error(res?.message)
    }
    else{
      toast.success(res?.message)
      setUsers(state => {
        return {
          ...state,
          docs: state?.docs.filter(user => user?.uuid !== user_uuid)
        }
      })
    }
    setRemoving(false)
  }

  return(
    <>
      {add && <AddUserToDepartment 
        open={add} 
        close={() => setAdd(false)} 
        data={data}
        setUsers={setUsers}
      /> }
      <Dialog
        open={open}
        onClose={close}
        className="w-full"
      >
        <div className='w-[500px]'>
          <DialogTitle className='capitalize'>{data?.name || "Department"}</DialogTitle>

          <DialogContent>
            <TableContainer component={Paper}>
            {loading && <LinearProgress /> }
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>email</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {users?.docs.map((user, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user?.first_name + " " + user?.last_name}
                        </TableCell>
                        <TableCell>{user?.email}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => removeUser(user?.uuid)}
                            size='small'
                          >
                            {
                              removing ? 
                              <CircularProgress size={20} /> :
                              <DeleteIcon fontSize='small' sx={{ color: "crimson" }}/>
                            }
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[15]}
                      count={users?.totalDocs}
                      rowsPerPage={users?.limit}
                      page={page}
                      onPageChange={(e, page) => setPage(page)}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions>
            <Button onClick={close}>Close</Button>
            <Button 
              variant='contained' 
              onClick={() => setAdd(true)}
              // loading={loading}
              autoFocus>
              Add User
            </Button>
          </DialogActions>

        </div>
      </Dialog>
    </>
  )
}

export const AddUserToDepartment = ({open, close, setUsers, data = null}) => {
  const [loading, setLoading] = useState(false)
  const [staffs, setStaffs] = useState()
  const [staff_uuid, setStaffUuid] = useState("")

  function submit(e){
    setLoading(true)
    e.preventDefault()

    addUserToDept(JSON.stringify({
      department_id: data?._id,
      user_uuid: staff_uuid,
      leader: true
    }))
    .then(data => {
      if(data?.error) toast.error(data?.message)
      else{
        toast.success(data?.message)
        
        setUsers(state => ({...state, docs: [...state?.docs, data?.data]}))
        close()
      }
      setLoading(false)
    })
    .catch(err => {
      if(err?.error) toast.error(err?.message)
      setLoading(false)
    })

  }

  useEffect(() => {
    console.log("Getting all staffs")
    getAllStaff()
      .then(data => {
        console.log("get staff", data)
        if(data?.error) return toast.error(data?.message)
        setStaffs(data?.data)
      })
      .catch(err => {
        console.log(err)
        if(err?.error) return toast.error(err?.message)
      })
  }, [])

  console.log(staffs)

  return (
    <Dialog
      open={open}
      onClose={close}
      className="w-full"
    >
      <DialogTitle>
        Add User To Lab
      </DialogTitle>
      <form onSubmit={submit} className='w-[500px]'>
        <DialogContent>
          <div className='my-1'>
            <FormControl fullWidth size="small">
              <InputLabel id="select-staff">Select Staff</InputLabel>
              <Select
                labelId="select-staff"
                id="select-staff"
                value={staff_uuid}
                label="Select Staff"
                onChange={(e) => setStaffUuid(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  staffs?.docs?.map(staff => (<MenuItem value={staff?._id}>{staff?.first_name + " " + staff?.last_name}</MenuItem>))
                }
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <LoadingButton 
            variant='contained' 
            type='submit'
            loading={loading}
            autoFocus>
            Submit 
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}


export default AddDepartment